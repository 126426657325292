const dev = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.vaccinator3000.com/dev",
  },
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.vaccinator3000.app/prod",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  // Add common config values here
  ...config,
};
