import React from "react";

// import classNames from "classnames";

const AutoTable = (props) => {
  return (
    <div className="flex flex-col mt-16">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="">
                <tr>
                  <th className="px-6 py-3  text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Store Name
                  </th>
                  {/* {props.rows.map((row) => {
                    return row.appointmentTimes.map((time) => {
                      return <th className="px-6 py-3 bg-gray-50"></th>;
                    });
                  })} */}
                  {/* <th className="px-6 py-3 bg-gray-50"></th>
                  <th className="px-6 py-3 bg-gray-50"></th>
                  <th className="px-6 py-3 bg-gray-50"></th>
                  <th className="px-6 py-3 bg-gray-50"></th>
                  <th className="px-6 py-3 bg-gray-50"></th>
                  <th className="px-6 py-3 bg-gray-50"></th>
                  <th className="px-6 py-3 bg-gray-50"></th> */}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.rows.map((row) => {
                  return (
                    <tr key={Math.random()}>
                      <td className="px-1 py-4 whitespace-no-wrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm leading-5 font-medium text-gray-900">
                              {row.address.slice(13)}
                            </div>
                            <div className="text-sm leading-5 text-gray-500">
                              Jewel-Osco
                            </div>
                          </div>
                        </div>
                      </td>
                      {row.appointmentTimes.map((time) => {
                        return (
                          <td key={Math.random()} className="px-2 py-4">
                            <button
                              type="button"
                              className="text-white text-sm py-2 elevation-2 text-center rounded bg-purple-600 hover:bg-purple-500 focus:outline-none"
                              onClick={() => {
                                props.onClickAutoBook({ row: row, time: time });
                                // window.open(row.link, "_blank");
                              }}
                            >
                              {time.date + " @ " + time.startTime}
                            </button>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoTable;
