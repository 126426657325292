import React, { Component } from "react";
import ReactLoading from "react-loading";
import backendService from "./api";
import {
  Header,
  Foundation,
  Table,
  AutoTable,
  Form,
  Login,
} from "./Components";
import ILStoreDetails from "./Constants/ILStoreDetails.json";

// var locations = [];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      locked: false,
      rows: [],
      userData: {},
      password: "",
      auto: false,
    };
  }

  componentDidMount() {}

  async runVaccinator() {
    this.setState({ loading: true });

    this.state.auto
      ? backendService.getAppointmentTimeSlots().then((response) => {
          console.log(
            "🚀 ~ backendService.getAppointmentTimeSlots ~ response",
            response
          );

          var locations = response.data.results;

          this.setState({ loading: false, rows: locations });
        })
      : backendService
          .getAppointmentSlots()
          .then((response) => {
            console.log(
              "🚀 ~ backendService.getAppointmentSlots ~ response",
              response
            );

            var locations = response.data.results;

            this.setState({
              loading: false,
              rows: locations,
            });
          })
          .catch((e) => {
            console.log("🚀 ~ :backendService.getAppointmentSlots ~ e", e);
          });
  }

  async bookAppointment(data) {
    this.setState({ loading: true });

    var userData = this.state.userData;

    if (this.state.locked === false) {
      alert("Please fill out the personal information below.");
    } else {
      var body = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        birthday: userData.birthday,
        gender: userData.gender,
        companyName: data.row.companyName,
        email: userData.email,
        phoneNumber: userData.phone,
        appointmentDate: data.time.date,
        appointmentTime: data.time.startTime,
        zipCode: userData.zipCode,
        city: userData.city,
        state: userData.state,
        address: userData.address,
        appointmentZipCode: data.row.zip,
        clientId: data.time.externalClientId,
      };

      backendService.bookAppointment(body).then((response) => {
        console.log("🚀 ~ backendService.bookAppointment ~ response", response);

        if (response.data.results === "\r\n\r\n\r\nsession expired") {
          backendService.bookAppointment(body).then((response) => {
            console.log(
              "🚀 ~ backendService.bookAppointment ~ response",
              response
            );

            if (response.data.results === "\r\n\r\n\r\nsession expired") {
              this.setState({ loading: false });
              alert(
                "There was an issue booking this appointment. Please try again."
              );
            } else if (
              response.data.results.Description === "Time slot not available"
            ) {
              this.setState({ loading: false });
              alert("Time slot not available. Please try again.");
            } else if (
              response.data.results.Description === "unable to generate link"
            ) {
              this.setState({ loading: false });
              alert("Unable to generate link. Please try again.");
            } else {
              this.setState({ loading: false });
              alert(
                "Appointment Booked! Please check your email immediately to confirm."
              );
            }
          });
        } else if (
          response.data.results.Description === "Time slot not available"
        ) {
          this.setState({ loading: false });
          alert("Time slot not available. Please try again.");
        } else if (
          response.data.results.Description === "unable to generate link"
        ) {
          this.setState({ loading: false });
          alert("Unable to generate link. Please try again.");
        } else {
          this.setState({ loading: false });
          alert(
            "Appointment Booked! Please check your email immediately to confirm."
          );
        }
      });
    }
  }

  setUserData(userData) {
    this.setState({ userData, locked: true });
  }

  unlockUserData() {
    this.setState({ userData: {}, locked: false });
  }

  setPassword(password) {
    this.setState({ password });
  }

  render() {
    if (this.state.password === process.env.REACT_APP_STAGE) {
      return (
        <div className="App">
          <Header
            form={
              <Form
                locked={this.state.locked}
                unlock={() => {
                  this.unlockUserData();
                }}
                submit={(data) => {
                  this.setUserData(data);
                }}
              />
            }
          >
            <Foundation
              onClick={() => {
                this.runVaccinator();
              }}
              disabled={this.state.loading}
              auto={this.state.auto}
              onClickToggle={() => {
                var auto = this.state.auto;

                auto === true ? (auto = false) : (auto = true);

                this.setState({ auto, rows: [] });
              }}
            >
              {this.state.loading ? (
                <div className="flex mt-16 items-center align-middle self-center justify-center">
                  <ReactLoading
                    type={"spin"}
                    color={"#039be5"}
                    height={"10%"}
                    width={"10%"}
                  />
                </div>
              ) : this.state.auto ? (
                <AutoTable
                  rows={this.state.rows}
                  onClickAutoBook={(data) => {
                    this.bookAppointment(data);
                    // console.log("🚀 ~ render ~ data", data);
                  }}
                />
              ) : (
                <Table rows={this.state.rows} />
              )}
            </Foundation>
          </Header>
        </div>
      );
    } else {
      return (
        <div className="App">
          <Login
            submit={(password) => {
              this.setPassword(password);
            }}
          />
        </div>
      );
    }
  }
}

export default App;
