import React from "react";

// import classNames from "classnames";

const Table = (props) => {
  return (
    <div className="flex flex-col mt-16">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Store Name
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Zip Code
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Appointment Dates
                  </th>
                  <th className="px-6 py-3 bg-gray-50"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.rows.map((row) => {
                  return (
                    <tr key={Math.random()}>
                      <td className="px-1 py-4 whitespace-no-wrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm leading-5 font-medium text-gray-900">
                              {row.address.slice(13)}
                            </div>
                            <div className="text-sm leading-5 text-gray-500">
                              Jewel-Osco
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        <div className="text-sm leading-5 text-gray-900">
                          {row.zip}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap">
                        {row.dates.slotDates.map((date) => {
                          return (
                            <div
                              key={date}
                              className="text-sm leading-5 text-gray-900"
                            >
                              {date}
                            </div>
                          );
                        })}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                        <div className="ml-4 mt-2 flex-shrink-0">
                          <span className="inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className="text-white font-bold py-2 px-4 min-w-96 elevation-2 text-center rounded bg-red-500 hover:bg-red-600 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700"
                              onClick={() => {
                                window.open(row.link, "_blank");
                              }}
                            >
                              Manual Book
                            </button>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}

                {/* <tr>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm leading-5 font-medium text-gray-900">
                          1340 Patriot Blvd, Glenview, IL, 60026
                        </div>
                        <div className="text-sm leading-5 text-gray-500">
                          Jewel-Osco
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="text-sm leading-5 text-gray-900">60026</div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="text-sm leading-5 text-gray-900">
                      2/19/2021, 2/20/2021, 2/21/2021, 2/22/2021
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                    <div className="ml-4 mt-2 flex-shrink-0">
                      <span className="inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="text-white font-bold py-2 px-4 min-w-96 elevation-2 text-center rounded bg-red-500 hover:bg-red-600 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700"
                        >
                          GO!
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm leading-5 font-medium text-gray-900">
                          21152 La Grange Road, Frankfort, IL, 60423
                        </div>
                        <div className="text-sm leading-5 text-gray-500">
                          Jewel-Osco
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="text-sm leading-5 text-gray-900">60423</div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="text-sm leading-5 text-gray-900">
                      2/19/2021, 2/20/2021, 2/21/2021, 2/22/2021
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                    <div className="ml-4 mt-2 flex-shrink-0">
                      <span className="inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="text-white font-bold py-2 px-4 min-w-96 elevation-2 text-center rounded bg-red-500 hover:bg-red-600 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700"
                        >
                          GO!
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm leading-5 font-medium text-gray-900">
                          1156 Maple Ave, Lisle, IL, 60532
                        </div>
                        <div className="text-sm leading-5 text-gray-500">
                          Jewel-Osco
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="text-sm leading-5 text-gray-900">60532</div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    <div className="text-sm leading-5 text-gray-900">
                      2/19/2021, 2/20/2021, 2/21/2021, 2/22/2021
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                    <div className="ml-4 mt-2 flex-shrink-0">
                      <span className="inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="text-white font-bold py-2 px-4 min-w-96 elevation-2 text-center rounded bg-red-500 hover:bg-red-600 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700"
                        >
                          GO!
                        </button>
                      </span>
                    </div>
                  </td>
                </tr> */}

                {/* <!-- More rows... --> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
