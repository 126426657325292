import React from "react";
import classNames from "classnames";

function format(inputDate) {
  var splitDate = inputDate.split("-");
  if (splitDate.count === 0) {
    return null;
  }

  var year = splitDate[0];
  var month = splitDate[1];
  var day = splitDate[2];

  return month + "/" + day + "/" + year;
}

const Form = (props) => {
  function handleSubmit() {
    var firstName = document.getElementById("FirstName").value;
    var lastName = document.getElementById("LastName").value;
    var birthday = format(document.getElementById("Birthday").value);
    var gender = document.getElementById("Gender").value;
    var email = document.getElementById("email").value;
    var phone = document.getElementById("phone").value;
    var address = document.getElementById("Address").value;
    var city = document.getElementById("City").value;
    var state = document.getElementById("State").value;
    var zipCode = document.getElementById("ZipCode").value;

    var formData = {
      firstName,
      lastName,
      birthday,
      gender,
      email,
      phone,
      address,
      city,
      state,
      zipCode,
    };

    props.submit(formData);
  }

  return (
    <div>
      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="FirstName"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          First Name
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="FirstName"
            type="text"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="LastName"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Last Name
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="LastName"
            type="text"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="Birthday"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Birthday
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="Birthday"
            type="date"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="Gender"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Gender
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select
            id="Gender"
            name="Gender"
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Email
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="email"
            type="email"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="phone"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Phone Number
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="phone"
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="Address"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Address
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="Address"
            type="text"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="City"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          City
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="City"
            type="text"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="State"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          State
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select
            id="State"
            name="State"
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          >
            <option value="IL">Illinois</option>
          </select>
        </div>
      </div>

      <div
        className={classNames(
          "mb-4",
          props.locked && "opacity-50 cursor-not-allowed"
        )}
      >
        <label
          htmlFor="ZipCode"
          className="block text-sm font-medium leading-5 text-gray-700"
        >
          Zip Code
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="ZipCode"
            type="text"
            pattern="[0-9]{5}"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            disabled={props.locked}
          />
        </div>
      </div>

      <div className="mt-6">
        <span className="block w-full rounded-md shadow-sm">
          <button
            type="submit"
            className={classNames(
              "w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white transition duration-150 ease-in-out",
              !props.locked &&
                "bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",
              props.locked &&
                "bg-orange-600 hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700"
            )}
            onClick={() => {
              if (props.locked === true) {
                props.unlock();
              } else {
                handleSubmit();
              }
            }}
          >
            {props.locked ? "Unlock" : "Lock In"}
          </button>
        </span>
      </div>
    </div>
  );
};

export default Form;
