import Amplify, { Auth, API } from "aws-amplify";
import qs from "querystring";
import axios from "axios";
import config from "./apiconfig";

class backendService {
  init() {
    Amplify.configure({
      API: {
        endpoints: [
          {
            name: "vaccinator",
            endpoint: config.apiGateway.URL,
            region: config.apiGateway.REGION,
          },
        ],
      },
    });
  }

  async getAppointmentSlots() {
    try {
      const response = await axios.get(
        `https://api.vaccinator3000.com/${process.env.REACT_APP_STAGE}/getAppointmentSlots`
      );

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAppointmentTimeSlots() {
    try {
      const response = await axios.get(
        `https://api.vaccinator3000.com/${process.env.REACT_APP_STAGE}/getAppointmentSlotsWithTimes`
      );

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async bookAppointment(data) {
    console.log("🚀 ~ bookAppointment ~ data", data);

    try {
      const response = await axios.post(
        `https://api.vaccinator3000.com/${process.env.REACT_APP_STAGE}/bookAppointment`,
        {
          body: data,
        }
      );

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // async getcsrfKey() {
  //   // console.log(config);
  //   // try {
  //   //   return API.get("vaccinator-backend", `/getKey`);
  //   // } catch (e) {
  //   //   throw e;
  //   // }

  //   try {
  //     const response = await axios.get(
  //       `https://api.vaccinator3000.com/${process.env.REACT_APP_STAGE}/getKey`
  //     );

  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   }
  // }

  // async getAppointmentSlots(csrfKey, JSESSIONID, store) {
  //   var body = {
  //     csrfKey: csrfKey,
  //     JSESSIONID: JSESSIONID,
  //     store: store,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `https://api.vaccinator3000.com/${process.env.REACT_APP_STAGE}/getAppointments`,
  //       {
  //         body: body,
  //       }
  //     );
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   }

  //   // var urlParams = qs.stringify({
  //   //   slotsYear: "2021",
  //   //   slotsMonth: "2",
  //   //   forceAllAgents: "",
  //   //   manualOptionAgents: "",
  //   //   companyName: store.clientName,
  //   //   eventType: "COVID Vaccine Dose 1 Appt",
  //   //   eventTitle: "",
  //   //   location: store.name,
  //   //   locationTimezone: "America/Chicago",
  //   //   csrfKey: csrfKey,
  //   // });

  //   // const config = {
  //   //   headers: {
  //   //     "Content-Type": "application/x-www-form-urlencoded",
  //   //     Cookie: `JSESSIONID=${JSESSIONID}`,
  //   //   },
  //   //   withCredentsials: true,
  //   // };

  //   // try {
  //   //   const response = await axios.post(
  //   //     `https://kordinator.mhealthcoach.net/loadEventSlotDaysForCoach.do?cva=true&type=registration&_r=311452152286231&csrfKey=${csrfKey}`,
  //   //     urlParams,
  //   //     config
  //   //   );

  //   //   return response;
  //   // } catch (error) {
  //   //   console.error(error);
  //   //   throw error
  //   // }
  // }

  async sleep(ms) {
    return new Promise((resolve) => {
      console.log("Waiting...");
      setTimeout(resolve, ms);
    });
  }
}

const instance = new backendService();

export default instance;
